import { httpClient as client, getToken } from "./client";

export function getMostTrendingThread(countryCode, threadCategoryId = null, page = 1) {
  return new Promise((resolve, reject) => {
    client
      .get(`thread/mostTrending`, {
        params: {
          country_code: countryCode,
          thread_category_id: threadCategoryId,
          page,
        },
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}

export function browse(countryCode, threadCategoryId = null, filterBy = null, page = 1) {
  return new Promise((resolve, reject) => {
    client
      .get(`thread/browse`, {
        params: {
          country_code: countryCode,
          thread_category_id: threadCategoryId,
          filter_by: filterBy,
          page,
        },
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}

export function search(countryCode, searchTerm, threadCategoryId = null, page = 1) {
  return new Promise((resolve, reject) => {
    client
      .get(`thread/search`, {
        params: {
          country_code: countryCode,
          thread_category_id: threadCategoryId,
          search_term: searchTerm,
          page,
        },
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}

export function getThread(id, shouldIncreaseViewCount = 1) {
  return new Promise((resolve, reject) => {
    client
      .get(`thread/${id}`, {
        headers: {Authorization: `Bearer ${getToken()}`},
        params: {
          should_increase_view_count: shouldIncreaseViewCount,
        },
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}
export function getThreadReplies(id, parentId = null, page = 1, perPage = 3) {
  return new Promise((resolve, reject) => {
    client
      .get(`thread/${id}/replies`, {
        params: {
          parent_id: parentId,
          page,
          per_page: perPage,
        },
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  })
}

export function createThread(data) {
  return new Promise((resolve, reject) => {
    client
      .post(
        "thread/post", data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'content-type': 'multipart/form-data',
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function editThread(id, data) {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `thread/edit/${id}`, data,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function voteThread(id, voteType) {
  return new Promise((resolve, reject) => {
    client
      .post(
        `thread/vote/${id}`, 
        {vote_type: voteType}, // up || down
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function deleteThread(id) {
  return new Promise((resolve, reject) => {
    client
      .delete(
        `thread/delete/${id}`, 
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function reportThread(id) {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `thread/report/${id}`, {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function replyThread(id, body, parentId = null) {
  return new Promise((resolve, reject) => {
    client
      .post(
        `thread/reply/${id}`, 
        {
          parent_id: parentId,
          body: body,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function voteThreadReply(id, voteType) {
  return new Promise((resolve, reject) => {
    client
      .post(
        `thread/reply/vote/${id}`, 
        {vote_type: voteType},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function reportThreadReply(id) {
  return new Promise((resolve, reject) => {
    client
      .patch(
        `thread/reply/report/${id}`, 
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}