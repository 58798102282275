import React from 'react';
import './CardThread.scss';

import {navigate} from 'gatsby';

function CardThread(props) {
  return (
    <>
      {/* Desktop Version */}
      <div className={"d-md-flex d-none card-trending align-items-stretch cursor-pointer " + props.className} onClick={() => navigate(`/thread?id=${props.id}`)}>
        {props.image && (
          <div className="img-trending" style={{backgroundImage: `url(${props.image})`}} />
        )}
        <div className="p-3 align-self-center trending-description-wrapper">
          <div className="d-flex align-items-center mb-1">
            <div className="asap-body mr-1">{props.name}</div>
            <img src={props.countryFlagImage} className="mx-2 image-flag" />
            <div className="mx-2 dotted" />
            <div className="asap-body text-harbor-gray">{props.topic}</div>
          </div>
          <div className="asap-headline mb-2 thread-title">{props.title}</div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="asap-body text-steel-gray">{props.time}</div>
              <div className="bg-abalone-gray mx-3 right-divider" />
              <i className="mdi mdi-eye mdi-18px text-harbor-gray mr-2" />
              <div className="asap-body text-steel-gray mr-3">{props.viewerCount}</div>
              <i className="mdi mdi-chat mdi-18px text-harbor-gray mr-2" />
              <div className="asap-body text-steel-gray">{props.chatCount}</div>
            </div>
            <div className="d-flex align-items-center">
              <i className="mdi mdi-arrow-up-bold-outline mdi-18px text-jade-green mr-2" />
              <div className="asap-body text-jade-green mr-3">{props.upVoteCount}</div>
              <i className="mdi mdi-arrow-down-bold-outline mdi-18px text-apple-red mr-2" />
              <div className="asap-body text-apple-red">{props.downVoteCount}</div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Version */}
      <div className={"d-md-none d-flex flex-column card-mobile-trending cursor-pointer " + props.className} onClick={() => navigate(`/thread?id=${props.id}`)}>
        <div className="d-flex align-items-center mb-2 header-wrapper">
          <div className="asap-body text-steel-gray">{props.time}</div>
          <div className="bg-abalone-gray mx-3 right-divider" />
          <i className="mdi mdi-eye mdi-18px text-harbor-gray mr-2" />
          <div className="asap-body text-steel-gray mr-3">{props.viewerCount}</div>
          <i className="mdi mdi-chat mdi-18px text-harbor-gray mr-2" />
          <div className="asap-body text-steel-gray">{props.chatCount}</div>
        </div>
        {props.image && (
          <div className="w-100 img-trending mb-2" style={{backgroundImage: `url(${props.image})`}} />
        )}
        <div className="d-flex align-items-center mb-1">
          <div className="asap-body mr-1">{props.name}</div>
          <img src={props.countryFlagImage} className="mx-2 image-flag" />
          <div className="mx-2 dotted" />
          <div className="asap-body text-harbor-gray">{props.topic}</div>
        </div>
        <div className="asap-headline mb-1">{props.title}</div>
        <div className="d-flex align-items-center">
          <i className="mdi mdi-arrow-up-bold-outline mdi-18px text-jade-green mr-2" />
          <div className="asap-body text-jade-green mr-3">{props.upVoteCount}</div>
          <i className="mdi mdi-arrow-down-bold-outline mdi-18px text-apple-red mr-2" />
          <div className="asap-body text-apple-red">{props.downVoteCount}</div>
        </div>
      </div>
    </>
  );
}

export default CardThread;
