import React, {useEffect, useState} from 'react';
import './Browse.scss';

import moment from 'moment-timezone';
import {useQueryParam, StringParam} from "use-query-params";
import {browse, getMostTrendingThread} from '@repository/ThreadRepository';
import DefaultProfilePicture from '@backgrounds/cerebro_default_pp.png';
import IcNotFound from '@icons/ic-not-found.png';
import CardThread from '@components/CardThread/CardThread';
import {navigate} from 'gatsby';

function Browse() {
  const [categoryId, setCategoryId] = useQueryParam("categoryid", StringParam);
  const [categoryTitle, setCategoryTitle] = useQueryParam("categorytitle", StringParam);
  const [mostTrendingThreads, setMostTrendingThreads] = useState([]);
  const [threads , setThreads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [isLoadMostTrendingThreads, setIsLoadMostTrendingThreads] = useState(false);
  const [isLoadBrowseThread, setIsLoadBrowseThread] = useState(false);
  const [filterBy, setFilterBy] = useState("all_time");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (currentPage == 1) {
      handleBrowseThread();
    } else {
      setCurrentPage(1);
    }
    handleMostTrendingThread();
  }, [categoryId]);

  useEffect(() => {
    generateAndSetPageNumbers();
  }, [lastPage, currentPage]);

  useEffect(() => {
    handleBrowseThread();
  }, [currentPage, filterBy]);

  const generateAndSetPageNumbers = () => {
    if (lastPage == 1) {
      setPageNumbers([]);
      return;
    }

    let firstPageNumber = 1;
    if (lastPage <= 5) {
      firstPageNumber = 1;
    } if (currentPage - 2 <= 0) {
      firstPageNumber = 1;
    } else if (currentPage + 2 >= lastPage) {
      firstPageNumber = lastPage - 4;
    } else {
      firstPageNumber = currentPage - 2;
    }

    if (firstPageNumber < 1) firstPageNumber = 1;

    let lastPageNumber = 5;

    if (lastPage <= 5) {
      lastPageNumber = lastPage;
    } else {
      lastPageNumber = firstPageNumber + 4;
    }

    let pageNumbersTemp = [];
    for (let i = firstPageNumber; i <= lastPageNumber; i++) {
      pageNumbersTemp.push(i);
    }
    setPageNumbers(pageNumbersTemp);
  }

  const handleMostTrendingThread = () => {
    setIsLoadMostTrendingThreads(true);
    let _categoryId = categoryId ? categoryId : null;
    let countryCode = null;
    let localStorageCountryString = localStorage.getItem('country');
    if (localStorageCountryString !== null) {
      let localStorageCountryJSON = JSON.parse(localStorageCountryString);
      countryCode = localStorageCountryJSON.alpha3Code;
    }

    getMostTrendingThread(countryCode, _categoryId)
      .then((res) => {
        setIsLoadMostTrendingThreads(false);
        setMostTrendingThreads(res.data.data);
      })
      .catch((err) => {
        setIsLoadMostTrendingThreads(false);
        console.log(err.response);
      });
  };

  const handleBrowseThread = () => {
    setIsLoadBrowseThread(true);
    let _categoryId = categoryId ? categoryId : null;

    let countryCode = null;
    let localStorageCountryString = localStorage.getItem('country');
    if (localStorageCountryString !== null) {
      let localStorageCountryJSON = JSON.parse(localStorageCountryString);
      countryCode = localStorageCountryJSON.alpha3Code;
    }
    
    browse(countryCode, _categoryId, filterBy, currentPage)
      .then((res) => {
        setIsLoadBrowseThread(false);
        setThreads(res.data.data);
        setLastPage(res.data.meta.last_page);
      })
      .catch((err) => {
        setThreads([]);
        setLastPage(1);
        setCurrentPage(1);
        setIsLoadBrowseThread(false);
        console.log(err.response);
      })
  };

  return (
    <div className="container d-flex flex-column flex-grow-1">
      {isLoadBrowseThread && isLoadMostTrendingThreads ? (
        <div className="mdi mdi-loading mdi-spin mdi-48px m-auto" />
      ) : (
        <>
          {mostTrendingThreads.length > 0 && categoryId && categoryTitle ? (
            <div className="d-flex asap-body mb-3 mt-5">
              <div className="text-azure-blue cursor-pointer" onClick={() => navigate('/browse')}>Categories</div>
              <div className="text-harbor-gray mx-2">/</div>
              <div className="text-coal-black">{categoryTitle}</div>
            </div>
          ) : (
            <div className={threads.length > 0 ? `pt-5` : `pt-0`} />
          )}
          {mostTrendingThreads.length == 1 && (
            <div id="browse-trendings">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="px-3 pb-3 img-trending-headline-1 cursor-pointer position-relative"
                    style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.1%, rgba(0, 0, 0, 1) 100%), url(${mostTrendingThreads[0].image_url ? mostTrendingThreads[0].image_url : DefaultProfilePicture})`}}
                    onClick={() => navigate(`/thread?id=${mostTrendingThreads[0]._id}`)}>
                    <div className="trending-headline-description-wrapper">
                      <div className="asap-body text-pure-white mb-1">{mostTrendingThreads[0].category.title}</div>
                      <div className="asap-headline text-pure-white mb-1">{mostTrendingThreads[0].title}</div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-eye mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[0].view_count}</div>
                          <i className="mdi mdi-chat mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white">{mostTrendingThreads[0].replies_count}</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-arrow-up-bold-outline mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[0].votes_up_count}</div>
                          <i className="mdi mdi-arrow-down-bold-outline mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white">{mostTrendingThreads[0].votes_down_count}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>        
            </div>
          )}
          {mostTrendingThreads.length == 2 && (
            <div id="browse-trendings">
              <div className="row">
                <div className="col-lg-6 mb-lg-0 mb-3">
                  <div
                    className="px-3 pb-3 img-trending-headline-2 cursor-pointer position-relative"
                    style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.1%, rgba(0, 0, 0, 1) 100%), url(${mostTrendingThreads[0].image_url ? mostTrendingThreads[0].image_url : DefaultProfilePicture })`}}
                    onClick={() => navigate(`/thread?id=${mostTrendingThreads[0]._id}`)}>
                    <div className="trending-headline-description-wrapper">
                      <div className="asap-body text-pure-white mb-1">{mostTrendingThreads[0].category.title}</div>
                      <div className="asap-headline text-pure-white mb-1">{mostTrendingThreads[0].title}</div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-eye mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[0].view_count}</div>
                          <i className="mdi mdi-chat mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white">{mostTrendingThreads[0].replies_count}</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-arrow-up-bold-outline mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[0].votes_up_count}</div>
                          <i className="mdi mdi-arrow-down-bold-outline mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white">{mostTrendingThreads[0].votes_down_count}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="px-3 pb-3 img-trending-headline-2 cursor-pointer position-relative"
                    style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.1%, rgba(0, 0, 0, 1) 100%), url(${mostTrendingThreads[1].image_url ? mostTrendingThreads[1].image_url : DefaultProfilePicture})`}}
                    onClick={() => navigate(`/thread?id=${mostTrendingThreads[1]._id}`)}>
                    <div className="trending-headline-description-wrapper">
                      <div className="asap-body text-pure-white mb-1">{mostTrendingThreads[1].category.title}</div>
                      <div className="asap-headline text-pure-white mb-1">{mostTrendingThreads[1].title}</div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-eye mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[1].view_count}</div>
                          <i className="mdi mdi-chat mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white">{mostTrendingThreads[1].replies_count}</div>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="mdi mdi-arrow-up-bold-outline mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[1].votes_up_count}</div>
                          <i className="mdi mdi-arrow-down-bold-outline mdi-18px text-pure-white mr-2" />
                          <div className="asap-body text-pure-white">{mostTrendingThreads[1].votes_down_count}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>        
            </div>
          )}
          {mostTrendingThreads.length > 2 && (
            <div id="browse-trendings">
              <div className="row">
                <div className="col-lg-5 mb-lg-0 mb-3">
                  <div
                    className="px-3 pb-3 img-trending-headline cursor-pointer"
                    style={{background: `linear-gradient(180deg, rgba(0, 0, 0, 0.1) 50.1%, rgba(0, 0, 0, 1) 100%), url(${mostTrendingThreads[0].image_url ? mostTrendingThreads[0].image_url : DefaultProfilePicture})`}}
                    onClick={() => navigate(`/thread?id=${mostTrendingThreads[0]._id}`)}>
                    <div className="asap-body text-pure-white mb-1">{mostTrendingThreads[0].category.title}</div>
                    <div className="asap-headline text-pure-white mb-1">{mostTrendingThreads[0].title}</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-eye mdi-18px text-pure-white mr-2" />
                        <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[0].view_count}</div>
                        <i className="mdi mdi-chat mdi-18px text-pure-white mr-2" />
                        <div className="asap-body text-pure-white">{mostTrendingThreads[0].replies_count}</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-arrow-up-bold-outline mdi-18px text-pure-white mr-2" />
                        <div className="asap-body text-pure-white mr-3">{mostTrendingThreads[0].votes_up_count}</div>
                        <i className="mdi mdi-arrow-down-bold-outline mdi-18px text-pure-white mr-2" />
                        <div className="asap-body text-pure-white">{mostTrendingThreads[0].votes_down_count}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 d-flex flex-column">
                  <div className="dosis-title-1 text-philadelphia-blue mb-3">
                    Most Trending Threads
                  </div>
                  <CardThread
                    className="mb-3"
                    id={mostTrendingThreads[1]._id}
                    countryFlagImage={mostTrendingThreads[1].user.country.flag}
                    image={mostTrendingThreads[1].image_url ? mostTrendingThreads[1].image_url : DefaultProfilePicture}
                    name={mostTrendingThreads[1].user.fullname}
                    topic={mostTrendingThreads[1].category.title}
                    title={mostTrendingThreads[1].title}
                    time={moment.unix(mostTrendingThreads[1].created_at).fromNow()}
                    viewerCount={mostTrendingThreads[1].view_count}
                    chatCount={mostTrendingThreads[1].replies_count}
                    upVoteCount={mostTrendingThreads[1].votes_up_count}
                    downVoteCount={mostTrendingThreads[1].votes_down_count}
                  />
                  <CardThread
                    className="mb-3"
                    id={mostTrendingThreads[2]._id}
                    countryFlagImage={mostTrendingThreads[2].user.country.flag}
                    image={mostTrendingThreads[2].image_url ? mostTrendingThreads[2].image_url : DefaultProfilePicture}
                    name={mostTrendingThreads[2].user.fullname}
                    topic={mostTrendingThreads[2].category.title}
                    title={mostTrendingThreads[2].title}
                    time={moment.unix(mostTrendingThreads[2].created_at).fromNow()}
                    viewerCount={mostTrendingThreads[2].view_count}
                    chatCount={mostTrendingThreads[2].replies_count}
                    upVoteCount={mostTrendingThreads[2].votes_up_count}
                    downVoteCount={mostTrendingThreads[2].votes_down_count}
                  />
                  <div
                    className="d-flex align-items-center cursor-pointer align-self-start"
                    onClick={() => navigate(`/most-trending${categoryId ? "/?categoryid="+categoryId : ''}${categoryTitle ? "&categorytitle="+categoryTitle : ''}`)}>
                    <div className="asap-body text-azure-blue mr-2">View More</div>
                    <i className="mdi mdi-arrow-right mdi-18px text-azure-blue" />
                  </div>
                </div>
              </div>        
            </div>
          )}
          <div id="browse-threads" className="py-5 d-flex flex-column">
            {categoryId && categoryTitle && (
              <div className="d-flex asap-body mb-2">
                <div className="text-azure-blue cursor-pointer" onClick={() => navigate('/browse')}>Categories</div>
                <div className="text-harbor-gray mx-2">/</div>
                <div className="text-coal-black">{categoryTitle}</div>
              </div>
            )}
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-3">
              <div className="dosis-title-1 text-philadelphia-blue mb-md-0 mb-2">
                Browse Threads
              </div>
              <div className={'form-group d-flex flex-column-reverse position-relative my-auto align-self-start'}>
                <select className="form-control" onChange={(e) => setFilterBy(e.target.value)}>
                  <option value="all_time">Any time</option>
                  <option value="past_24_hours">Past 24 hours</option>
                  <option value="past_week">Past Week</option>
                  <option value="past_month">Past Month</option>
                </select>
                <label className='asap-body text-harbor-gray'>
                  Filter By
                </label>
                <i className="mdi mdi-menu-down select-icon-drop-down text-harbor-gray" />
              </div>
            </div>
            {isLoadBrowseThread ? (
              <div className="mdi mdi-loading mdi-spin mdi-48px mx-auto py-5 my-5" />
            ): (
              threads.length > 0 ? (
                <>
                  <div className="row">
                    {threads.map((thread) => (
                      <div className="col-xl-6">
                        <CardThread
                          id={thread._id}
                          key={thread._id}
                          className="mb-3"
                          countryFlagImage={thread.user.country.flag}
                          image={thread.image_url ? thread.image_url : DefaultProfilePicture}
                          name={thread.user.fullname}
                          topic={thread.category.title}
                          title={thread.title}
                          time={moment.unix(thread.created_at).fromNow()}
                          viewerCount={thread.view_count}
                          chatCount={thread.replies_count}
                          upVoteCount={thread.votes_up_count}
                          downVoteCount={thread.votes_down_count}
                        />
                      </div>
                    ))}
                  </div>
                  <div className={`align-items-center justify-content-center pt-4 ${lastPage <= 1 ? "d-none" : "d-flex"}`}>
                    <div
                      className={`mdi mdi-chevron-double-left mdi-18px cursor-pointer mr-2 ${currentPage === 1 ? "text-abalone-gray" : "text-philadelphia-blue"}`}
                      onClick={() => {
                        if (currentPage !== 1) {
                          setCurrentPage(prevValue => prevValue - 1);
                        }
                      }} />
                    {pageNumbers.map((pageNumber, index) => {
                      return (
                        <div
                          key={`number-${index}`}
                          className={
                            "asap-body mx-2 " +
                            (pageNumber == currentPage ? "text-philadelphia-blue font-weight-bold" : "text-iron-gray cursor-pointer")
                          }
                          onClick={() => {
                            if (pageNumber == currentPage) return;
                            setCurrentPage(pageNumber);
                          }}>
                          {pageNumber}
                        </div>
                      );
                    })}
                    <div
                      className={`mdi mdi-chevron-double-right mdi-18px cursor-pointer mr-2 ${currentPage === lastPage ? "text-abalone-gray" : "text-philadelphia-blue"}`}
                      onClick={() => {
                        if (currentPage !== lastPage) {
                          setCurrentPage(prevValue => prevValue + 1);
                        }
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center py-5 my-3">
                  <img src={IcNotFound} className="mb-3" />
                  <div className="dosis-title-1 text-philadelphia-blue">Thread Not Found</div>
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Browse;
